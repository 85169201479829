/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Fullmap, FullmapWrap, Column, ColumnWrap, ColumnWrapper, Title, Text, Image, Subtitle } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"O nás"} description={"O nás"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Fullmap name={"q13ep9ji9wi"}>
          
          <FullmapWrap className="--full">
          </FullmapWrap>

        </Fullmap>


        <Fullmap name={"lgz2ao2y4fp"}>
          
          <FullmapWrap className="--full">
          </FullmapWrap>

        </Fullmap>


        <Column className="pb--60 pt--60" name={"b9xks94vn0i"} layout={"l13"}>
          
          <ColumnWrap className="column__flex --left el--2 flex--top" columns={"2"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":480}}>
              
              <Title className="title-box" content={"KLÍČOVÁ SLUŽBA MOLEK"}>
              </Title>

              <Text className="text-box" content={"Jmenuji se Petr Molek. Vystudoval jsem obor Telekomunikační technik se zaměřením na telekomunikační a zabezpečovací techniku. V letech 2010 až 2014 jsem pracoval na pozici montážního technika v odvětví mechanických bezpečnostních systémů jako jsou cylindrické vložky, zámky, trezory a dveřní příslušenství. Po odchodu jsem začal pracovat jako technik výroby domácích dorozumívacích systémů. Postupem času jsem prošel přes výstupní kontrolu až na vývojové oddělení kde jsem se podílel na vývoji docházkových systémů a konstrukcích nových produktů. Na začátku roku 2020 jsem opustil pozici zaměstnance a založil živnost v odvětví elektroinstalací. Převážně jsem se snažil soustředit na produkty zvonkových a docházkových systému. A jelikož tyto systémy jdou ruku v ruce s elektromechanickými prvky vstupních dveří, sloučil jsem zkušenosti z obou těchto odvětví ve kterých jsem pracoval. \nV říjnu 2021 jsme se rozšířili o zaměstnance v nově otevřené prodejně KLÍČOVÁ SLUŽBA MOLEK v Novém Jičíně kde kromě výroby klíčů nabízíme produkty z obou odvětví. \nDíky zkušenostem v obou těchto odvětvích jsme schopni Vám nabídnout komplexní řešení mechanického zabezpečení a elektronické kontroly pohybu osob. Věříme že budete s našimi  službami spokojeni."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/19567/dae549d5b0e54cc18577d5ef93fd4c69_s=860x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/19567/dae549d5b0e54cc18577d5ef93fd4c69_s=350x_.png 350w, https://cdn.swbpg.com/t/19567/dae549d5b0e54cc18577d5ef93fd4c69_s=660x_.png 660w, https://cdn.swbpg.com/t/19567/dae549d5b0e54cc18577d5ef93fd4c69_s=860x_.png 860w, https://cdn.swbpg.com/t/19567/dae549d5b0e54cc18577d5ef93fd4c69_s=1400x_.png 1400w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"prázdná"} layout={"l30"}>
        </Column>


        <Column className="--l8w pb--60 pt--60" name={"fwvyak2kqj"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/19567/4426ddc87630454e8a71446100a7c968_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/19567/4426ddc87630454e8a71446100a7c968_s=350x_.jpg 350w, https://cdn.swbpg.com/t/19567/4426ddc87630454e8a71446100a7c968_s=660x_.jpg 660w, https://cdn.swbpg.com/t/19567/4426ddc87630454e8a71446100a7c968_s=860x_.jpg 860w, https://cdn.swbpg.com/t/19567/4426ddc87630454e8a71446100a7c968_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/19567/51c7cf304c294c2caca3bf9900bd3ae2_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/19567/51c7cf304c294c2caca3bf9900bd3ae2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/19567/51c7cf304c294c2caca3bf9900bd3ae2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/19567/51c7cf304c294c2caca3bf9900bd3ae2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/19567/51c7cf304c294c2caca3bf9900bd3ae2_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--l8w pb--60 pt--60" name={"9c93acf13o"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/19567/4bcb5fd9370346aba932660e1a21495f_s=660x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/19567/4bcb5fd9370346aba932660e1a21495f_s=350x_.png 350w, https://cdn.swbpg.com/t/19567/4bcb5fd9370346aba932660e1a21495f_s=660x_.png 660w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image style={{"maxWidth":1000}} alt={""} src={"https://cdn.swbpg.com/t/19567/96cf6326d8a9428da914f2018a8e7d3a_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/19567/96cf6326d8a9428da914f2018a8e7d3a_s=350x_.jpg 350w, https://cdn.swbpg.com/t/19567/96cf6326d8a9428da914f2018a8e7d3a_s=660x_.jpg 660w, https://cdn.swbpg.com/t/19567/96cf6326d8a9428da914f2018a8e7d3a_s=860x_.jpg 860w, https://cdn.swbpg.com/t/19567/96cf6326d8a9428da914f2018a8e7d3a_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--l8w pb--60 pt--60" name={"9c93acf13o"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/19567/500bc020c9f24bdea75687ff89b3f24e_s=660x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/19567/500bc020c9f24bdea75687ff89b3f24e_s=350x_.png 350w, https://cdn.swbpg.com/t/19567/500bc020c9f24bdea75687ff89b3f24e_s=660x_.png 660w, https://cdn.swbpg.com/t/19567/500bc020c9f24bdea75687ff89b3f24e_s=860x_.png 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center" name={"07da46f6r1dc"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center fs--72" content={"Naši partneři"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80" name={"goze73r774u"} layout={"l3"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":1000}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/19567/c0ae4fd33cb44972971b0dd4ef6765d0_s=860x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/19567/c0ae4fd33cb44972971b0dd4ef6765d0_s=350x_.png 350w, https://cdn.swbpg.com/t/19567/c0ae4fd33cb44972971b0dd4ef6765d0_s=660x_.png 660w, https://cdn.swbpg.com/t/19567/c0ae4fd33cb44972971b0dd4ef6765d0_s=860x_.png 860w, https://cdn.swbpg.com/t/19567/c0ae4fd33cb44972971b0dd4ef6765d0_s=1400x_.png 1400w"}>
              </Image>

              <Subtitle className="subtitle-box" content={"Kompletní zabezpečení pro Váš dům, byt i firmu\n"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image style={{"maxWidth":1000}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/19567/3c1e4fce8ee84472a54eaa014802f36b_s=860x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/19567/3c1e4fce8ee84472a54eaa014802f36b_s=350x_.png 350w, https://cdn.swbpg.com/t/19567/3c1e4fce8ee84472a54eaa014802f36b_s=660x_.png 660w, https://cdn.swbpg.com/t/19567/3c1e4fce8ee84472a54eaa014802f36b_s=860x_.png 860w, https://cdn.swbpg.com/t/19567/3c1e4fce8ee84472a54eaa014802f36b_s=1400x_.png 1400w"}>
              </Image>

              <Subtitle className="subtitle-box" content={"Bytové komunikační a přístupové systémy"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image style={{"maxWidth":1000}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/19567/97ceee5c6c034d70949c4798bb3f95de_s=860x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/19567/97ceee5c6c034d70949c4798bb3f95de_s=350x_.png 350w, https://cdn.swbpg.com/t/19567/97ceee5c6c034d70949c4798bb3f95de_s=660x_.png 660w, https://cdn.swbpg.com/t/19567/97ceee5c6c034d70949c4798bb3f95de_s=860x_.png 860w, https://cdn.swbpg.com/t/19567/97ceee5c6c034d70949c4798bb3f95de_s=1400x_.png 1400w"}>
              </Image>

              <Subtitle className="subtitle-box" content={"Komunikační a bezpečnostní technologie"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80" name={"goze73r774u"} layout={"l3"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":317}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/19567/52941fcd28944dac8ff456608670c821_s=660x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/19567/52941fcd28944dac8ff456608670c821_s=350x_.png 350w, https://cdn.swbpg.com/t/19567/52941fcd28944dac8ff456608670c821_s=660x_.png 660w"}>
              </Image>

              <Subtitle className="subtitle-box" content={"Elektroinstalace a solární systémy"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="pt--10" style={{"maxWidth":1000}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/19567/7859da0229744f7b8191ae2c70ab599c_s=350x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/19567/7859da0229744f7b8191ae2c70ab599c_s=350x_.jpg 350w"}>
              </Image>

              <Subtitle className="subtitle-box" content={"Pojištění nemovitosti<br><br>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="pt--02" style={{"maxWidth":117}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/19567/e903b92332734840922bee8daa831d53_s=350x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/19567/e903b92332734840922bee8daa831d53_s=350x_.png 350w"}>
              </Image>

              <Subtitle className="subtitle-box" content={"Tomi-stav<br>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1pn2jyn pb--80 pt--80" name={"kontakt"} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=350x_.png);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=660x_.png);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=860x_.png);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=1400x_.png);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=2000x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=660x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=1400x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=3000x_.png);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s3" anim={"7"} animS={"3"}>
              
              <Title className="title-box fs--62" content={"<span style=\"color: white;\">Rádi vyhovíme Vašim přáním a požadavkům</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}